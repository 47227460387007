// =============================================
// 1. GLOBAL DEFAULTS
// =============================================
* {
  box-sizing: border-box; 
  font-family: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas, 'DejaVu Sans Mono', monospace;
  font-weight: normal;
  color: black;
  font-size: calc(0.5rem + 0.5vw);
  border-radius: 0.5rem;
}

body {
  background-color: honeydew;
}

// =============================================
// 2. LAYOUT STRUCTURES
// =============================================
// Containers
.search-input-container,
.search-result-container {
  margin: auto;
  max-width: 70vw;
}

.search-result-container {
  border: 0.25rem solid thistle;
  padding: 1rem;
}

.search-input {
  padding: 1rem;
}

// Category grids
.category-container {
  column-count: 3;
  column-gap: 20px;
  max-width: 95vw;
  width: 95vw;
  margin: 0 auto;
}

.category-section {
  display: inline-block;
  width: 100%;
  background: #f9f9f9;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  
  ul {
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }
  
  li {
    font-size: 16px;
    padding: 5px 0;
    
    a {
      text-decoration: none;
      color: #0044cc;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Recent posts grid
.recent-posts-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
}

.recent-post {
  background: #f9f9f9;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
  
  h3 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  p {
    font-size: 16px;
  }
}

.post-date {
  font-weight: bold;
  color: gray;
}

// =============================================
// 3. FORM ELEMENTS
// =============================================
form > * {
  max-width: 70vw;
  margin-bottom: 1rem; 
  padding: 0.5rem;    
  border: none;
  text-align: left;
}

input,
textarea,
select {
  border: 0.25rem solid #ccc;
}

input[type=text] {
  column-count: 10;
  padding: 1rem 2rem;
  margin: 1rem 0;
}

input:invalid {
  border: 0.25rem dashed red;
}

input:valid {
  border: 0.25rem solid black;
}

textarea {
  height: 20rem;
  width: 35rem; 
  max-width: 85%;
  max-height: 40%;
  padding: 0.5rem;
  border: 0.25rem solid #ccc;
  background-color: #191970; 
  color: white;
  resize: both;
  margin: 1rem;
}

#clumpName {
  height: 3rem;
  width: 50rem;
}

// Form sections
section {
  overflow-x: auto;
  font-weight: normal;
  padding: 1rem;
  border: 0.25rem solid #ccc;
  background-color: #e5f9ff;
  font-size: 1rem;
  max-width: 70vw;
  margin: 1rem;
  
  label {
    max-width: 70vw;
    font-weight: bolder;
    overflow: auto;
    padding: 0.25rem;
    border: 0.25rem solid #ccc;
    background-color: #ccc9c0;
    font-size: 1rem;
  }
}

// Details/summary
details > summary {
  padding: 4px;
  width: 200px;
  background-color: #eeeeee;
  border: none;
  box-shadow: 1px 1px 2px #bbbbbb;
  cursor: pointer;
}

details > p {
  background-color: #eeeeee;
  padding: 4px;
  margin: 0;
  border: none;
  box-shadow: 1px 1px 2px #bbbbbb;
}

// =============================================
// 4. BUTTONS & INTERACTIVE
// =============================================
button {
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0.25rem solid darkgrey;
  padding: 0.5rem;    
  box-shadow: 0.25rem 0.25rem 0.25rem grey;
  
  &.note-btn {
    background-color: orangered;
  }
  
  &.edit-btn {
    background-color: dimgrey;
  }
}

footer button {
  margin: 0.25rem;
  padding: 0.25rem;    
}

#div-copy-button-success {
  max-width: 50vw;
  display: none;
  overflow-x: auto;
  font-weight: bolder;
  padding: 0.5rem;
  border: 0.25rem solid #ccc;
  background-color: #cee0e6;
  font-size: 1rem;
  color: darkorange;
}

.btn-success {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.copybtn i {
  margin-right: 5px;
}
.controls {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
}
#export-btn, #import-btn {
  white-space: nowrap;
}
// =============================================
// 5. UTILITY CLASSES
// =============================================
.p-small { padding-bottom: 0.5rem; }
.p-medium { padding-bottom: 1rem; }
.p-y { padding: 1rem 0rem; }
.m-y { margin: 1rem 0rem; }

.note {
  margin: 1rem;
  padding: 0.5rem;  
  border: 0.25rem solid #777; 
  background-color: #fff;
  overflow-y: auto;
  
  .note-title {
    font-size: 1rem;
    color: black;
  }
}

// =============================================
// 6. SITE COMPONENTS
// =============================================
img {
  max-width: 25vw;
  display: block;
  margin-left: auto;
  margin-right: auto;  
}

.site-footer {
  font-size: 0.75rem;
  
  .wrapper {
    margin: auto;
    font-size: 0.625rem;
    text-align: center;
  }
}

.site-title {
  font-size: 1.5rem;
}

.rss-subscribe {
  display: none;
}

// =============================================
// 7. PAGINATION
// =============================================
.pagination {
  text-align: center;
  margin-top: 30px;
  
  a {
    padding: 10px 15px;
    border: 1px solid #ccc;
    text-decoration: none;
    margin: 5px;
    border-radius: 5px;
    
    &:hover {
      background: #ddd;
    }
  }
  
  span {
    font-size: 16px;
    font-weight: bold;
  }
}

// =============================================
// 8. RESPONSIVE ADJUSTMENTS
// =============================================
@media (max-width: 1000px) {
  .category-container {
    column-count: 2;
  }
  
  .recent-posts-container {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .page-content, 
  .site-header, 
  .site-footer {
    max-width: 95vw !important;
    width: 95vw !important;
  }
}

@media (max-width: 700px) {
  .category-container {
    column-count: 1;
  }
  
  .recent-posts-container {
    grid-template-columns: 1fr;
  }
}

// Full-width overrides
.site-header, 
.site-footer {
  max-width: 1400px !important;
  width: 90%;
}

.page-content {
  max-width: 95vw !important;
}

.site, 
.wrapper {
  max-width: 100vw !important;
}