* {
  box-sizing: border-box; 
  font-family: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas, 'DejaVu Sans Mono', monospace;
  font-weight: normal;
  color: black;
  font-size: calc(0.5rem + 0.5vw);
  border-radius: 0.5rem;
}

.search-input-container {
  margin: auto;
  max-width: 70vw;
}

body {
  background-color: honeydew;
}

img {
  max-width: 25vw;
  display: block;
  margin-left: auto;
  margin-right: auto;  
}

.search-result-container {
  margin: auto;
  border: 0.25rem solid thistle;
  padding: 1rem;
  max-width: 70vw;
}

.search-input {
  margin: auto;
  padding: 1rem;
  max-width: 70vw;
}

.site-footer {
  font-size: 0.75rem;
}

form > *{
  max-width: 70vw;
  margin-bottom: 1rem; 
  padding: 0.5rem;    
  border: none;
  text-align: left;
}

input,
textarea,
select {
  border: 0.25rem solid #ccc;
}

form > details{
  border: none;
}

section label {
  max-width: 70vw;
  font-weight: bolder;
  overflow: auto;
  padding: 0.25rem;
  border: 0.25rem solid #ccc;
  background-color: #ccc9c0;
  font-size: 1rem; /* 14px/16=0.875em */
}

section {
  overflow-x:auto;
  font-weight: normal;
  padding: 1rem;
  border: 0.25rem solid #ccc;
  background-color: #e5f9ff;
  font-size: 1rem;
  max-width: 70vw;
  margin: 1rem;
}

input[type=text] {
  column-count: 10;
  padding: 1rem 2rem;
  margin: 1rem 0;
}

input:invalid {
  border: 0.25rem dashed red;
}

input:valid {
  border: 0.25rem solid black;
}

details > summary {
  padding: 4px;
  width: 200px;
  background-color: #eeeeee;
  border: none;
  box-shadow: 1px 1px 2px #bbbbbb;
  cursor: pointer;
}

details > p {
  background-color: #eeeeee;
  padding: 4px;
  margin: 0;
  border: none;
  box-shadow: 1px 1px 2px #bbbbbb;
}

#div-copy-button-success {
  max-width: 50vw;
  display: none;
  overflow-x:auto;
  font-weight: bolder;
  padding: 0.5rem;
  border: 0.25rem solid #ccc;
  background-color: #cee0e6;
  font-size: 1rem;
  color: darkorange;
}

/* UTILITY */
.p-small {
  padding-bottom: 0.5rem;
}
.p-medium {
  padding-bottom: 1rem;
}
.p-y {
  padding: 1rem 0rem;
}
.m-y {
  margin: 1rem 0rem;
}

textarea {
  height: 20rem;
  width: 35rem; 
  max-width: 85%;
  max-height: 40%;
  padding: 0.5rem;
  border: 0.25rem solid #ccc;
  background-color: #191970; 
  color: white;
/*  background-color: #f8f8f8; */
  box-sizing: border-box; 
  resize: both;
  margin: 1rem;
}

#clumpName {
  height: 3rem;
  width: 50rem;
}

button {
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0.25rem solid darkgrey;
  padding: 0.5rem;    
  box-shadow: 0.25rem 0.25rem 0.25rem grey;
}

footer button {
  border-radius: 0.5rem;
  margin: 0.25rem;
  border: 0.25rem solid darkgrey;
  padding: 0.25rem;    
  box-shadow: 0.25rem 0.25rem 0.25rem grey;
}

.note {
  margin: 1rem;
  padding: 0.5rem;  
  border: 0.25rem solid #777; 
  background-color: #fff;
  overflow-y: auto;
}

/*.note-counter,
.note-text {
  font-size: 1rem;
}*/

.note-title {
/*    font-family: Arial, Helvetica, sans-serif; */
  font-size: 1rem;
  color: black;
}

.note-btn {
  background-color: orangered;
}

.edit-btn {
  background-color: dimgrey;
}

/*
#saved-sets {
  border: 0.25rem solid darkgrey;
  padding: 0.5rem;
  margin: 1rem;
}

#set-form {
  border: 0.25rem solid darkgrey;
  padding: 0.5rem;
  margin: 1rem;
}
*/
.rss-subscribe{
  display: none;
}
.site-title{
  font-size: 1.5rem;
}

footer .wrapper{
  margin:auto;
  font-size: 0.625rem;
  text-align: center;
}

.category-container {
  column-count: 3; /* Keeps Craigslist-style layout */
  column-gap: 20px; /* Space between columns */
  max-width: 95vw; /* Matches the new full width */
  width: 95vw;
  margin: 0 auto;
}

.category-section {
  display: inline-block; /* Ensures proper stacking in columns */
  width: 100%; /* Each category fills its column */
  background: #f9f9f9;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
  margin-bottom: 20px; /* Ensures spacing between rows */
}

.category-section ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.category-section li {
  font-size: 16px;
  padding: 5px 0;
}

.category-section li a {
  text-decoration: none;
  color: #0044cc;
}

.category-section li a:hover {
  text-decoration: underline;
}

.recent-posts-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Forces three equal columns */
  gap: 20px; /* Space between columns */
  max-width: 1400px; /* Expands width */
  width: 90%;
  margin: 0 auto;
}

.recent-post {
  background: #f9f9f9;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
}

.recent-post h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.recent-post p {
  font-size: 16px;
}

.post-date {
  font-weight: bold;
  color: gray;
}

/* Pagination Styling */
.pagination {
  text-align: center;
  margin-top: 30px;
}

.pagination a {
  padding: 10px 15px;
  border: 1px solid #ccc;
  text-decoration: none;
  margin: 5px;
  border-radius: 5px;
}

.pagination a:hover {
  background: #ddd;
}

.pagination span {
  font-size: 16px;
  font-weight: bold;
}


@media (max-width: 1000px) {
  .category-container {
      column-count: 2; /* Two columns for tablets */
  }
}

@media (max-width: 700px) {
  .category-container {
      column-count: 1; /* Single column for mobile */
  }
}

/* Ensure responsiveness */
@media (max-width: 1000px) {
  .recent-posts-container {
      grid-template-columns: repeat(2, 1fr); /* Two columns for tablets */
  }
}

@media (max-width: 700px) {
  .recent-posts-container {
      grid-template-columns: repeat(1, 1fr); /* One column for mobile */
  }
}

/* Increase max width for all pages */
@media (min-width: 1000px) {
  .page-content, .site-header, .site-footer {
      max-width: 95vw !important; /* Uses 95% of the viewport width */
      width: 95vw !important; /* Expands fully */
      margin: 0 auto;
  }
}

.site-header, .site-footer {
  max-width: 1400px !important;
  width: 90%;
  margin: 0 auto;
}

.page-content {
  max-width: 95vw !important; /* Overrides Minima's narrow default */
}

.site {
  max-width: 100vw !important; /* Ensures site container is full width */
}

.wrapper {
  max-width: 100vw !important; /* Ensures the entire wrapper isn't restricted */
}